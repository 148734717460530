'use client';

import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import s9 from 'style9';

import TotalCount from './TotalCount';

import type { ActionBtnProps } from '../../types';

// `c` is short for `classNames`
const c = s9.create({
  clickArea: {
    columnGap: space(1),
    display: 'flex',
    justifyContent: 'start',
  },
});

export default function CommentsBtn({
  clickAreaStyleExtend = [],
  iconStyleExtend,
  textStyleExtend,
  id,
}: ActionBtnProps) {
  const biAction = useBi();

  return (
    <ClickArea
      as="a"
      styleExtend={[c.clickArea, ...clickAreaStyleExtend]}
      rippleSize="small"
      href="#comments-section"
      data-testid="comments-btn"
      title={fork({ default: 'מעבר לתגובות', hdc: 'Comments' })}
      id={id}
      onClick={() => {
        biAction({
          feature: 'Article Page - Skip to Comments',
          feature_type: 'Content',
          action_id: 111,
        });
      }}
    >
      <Icon icon="comments" styleExtend={iconStyleExtend} variant="neutral" />
      <VisuallyHidden>{fork({ default: 'תגובות: ', hdc: 'Comments: ' })}</VisuallyHidden>
      <TotalCount textStyleExtend={textStyleExtend} />
    </ClickArea>
  );
}
