'use client';

import mq from '@haaretz/l-mq.macro';
import typesetter from '@haaretz/l-type.macro';
import useCommentsCount from '@haaretz/s-client-data-hooks/comments/useCommentsCount';
import React from 'react';
import s9 from 'style9';

import { ActionBtnProps } from '../../types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    ...typesetter(-2),

    ...mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
  },
});

interface TotalCountProps {
  textStyleExtend: ActionBtnProps['textStyleExtend'];
}

function TotalCount({ textStyleExtend = [] }: TotalCountProps) {
  const [totalCount, setTotalCount] = React.useState<number>(0);

  const { data } = useCommentsCount({ enabled: typeof window !== 'undefined' });

  const updatedTotalCount = data?.totalCount;

  React.useEffect(() => {
    updatedTotalCount && setTotalCount(updatedTotalCount);
  }, [updatedTotalCount]);

  return totalCount > 0 ? (
    <span data-test="total-comments-count" className={s9(c.base, ...textStyleExtend)}>
      {totalCount}
    </span>
  ) : null;
}

export default function TotalCountWithSuspense(props: TotalCountProps) {
  return (
    // TODO: replace with real fallback
    <React.Suspense fallback={null}>
      <TotalCount {...props} />
    </React.Suspense>
  );
}
