'use client';

import AlertsSubscriptionPopover from '@haaretz/s-alerts-subscription-popover';
import useUser from '@haaretz/s-atoms/user';
import Chip, { chipBaseStyle } from '@haaretz/s-chip';
import useTagAlertsRegistration from '@haaretz/s-client-data-hooks/alerts/useTagAlertsRegistration';
import useUserAlertsUnsubscribe from '@haaretz/s-client-data-hooks/alerts/useUserAlertsUnsubscribe';
import useUserIsSubscribed from '@haaretz/s-client-data-hooks/alerts/useUserIsSubscribed';
import { site } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { TagPageFragment } from '@haaretz/s-fragments/TagPage';

interface ArticleTagProps extends TagPageFragment {
  asLink?: boolean;
}

export default function ArticleTag({ name, contentId, url, asLink = false }: ArticleTagProps) {
  const user = useUser('cookieValue');
  const tagRef = React.useRef(null);
  const [isRegistered, setIsRegistered] = React.useState<boolean>(false);
  const [isLoggedIn] = React.useState(user.isLoggedIn);
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const biAction = useBi();
  const handleOpenPopoverStateChangeHandler = (boolean: boolean) => {
    setOpenPopover(boolean);
  };

  const { data: dataTag, isSuccess } = useUserIsSubscribed({
    resourceId: contentId,
    resourceType: 'articleTag',
    site,
    email: user?.userMail,
  });

  const biDataForRegisteredUsers = {
    campaign_name: 'follow tag',
    feature: 'TagAlert - article page',
    feature_type: 'Content',
    newsletter_segment_id: contentId,
    newsletter_segment_name: name,
    newsletter_email: user.userMail,
  };

  const tagAlertsMutation = useTagAlertsRegistration();
  const userAlertsUnsubscribeMutation = useUserAlertsUnsubscribe();
  const handleAnonymousOnClick = () => {
    biAction({
      action_id: 91,
      campaign_details: 'open UI',
      campaign_name: 'follow tag',
      feature: 'TagAlert - article page',
      feature_type: 'Content',
      newsletter_segment_id: contentId,
      newsletter_segment_name: name,
    });
  };
  const handleOnClick = async () => {
    if (!isRegistered) {
      tagAlertsMutation.mutate({
        isUnsubscribe: false,
        site: 'htz',
        resourceId: contentId,
      });
      setIsRegistered(true);
      biAction({
        action_id: 9,
        campaign_details: 'follow',
        ...biDataForRegisteredUsers,
      });
    }
    // unsubscribe
    if (isRegistered) {
      userAlertsUnsubscribeMutation.mutate({
        isUnsubscribe: true,
        json: 'true',
        site: 'htz',
        resourceId: contentId,
      });
      setIsRegistered(false);
      biAction({
        action_id: 8,
        campaign_details: 'unfollow',
        ...biDataForRegisteredUsers,
      });
    }
  };

  React.useEffect(() => {
    setIsRegistered(!!dataTag?.subscribed);
  }, [dataTag?.subscribed, isSuccess]);

  if (!name || !contentId) return null;

  return asLink ? (
    <HtzLink href={url} className={s9(chipBaseStyle)}>
      {name}
    </HtzLink>
  ) : (
    <>
      <Chip
        as="button"
        kind="pressable"
        isPressed={isRegistered === true}
        onClick={isLoggedIn ? handleOnClick : handleAnonymousOnClick}
        ref={tagRef}
      >
        {name}
      </Chip>
      {isLoggedIn ? null : (
        <AlertsSubscriptionPopover
          setRegistrationSuccess={setIsRegistered}
          isOpenFromParent={openPopover}
          contentId={contentId}
          resourceType="tag"
          onToggle={handleOpenPopoverStateChangeHandler}
          refersToRef={tagRef}
          popoverPlacement={'top-start'}
          tagName={name}
        />
      )}
    </>
  );
}
