import useArticleId from '@haaretz/s-atoms/articleId';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';

import useClientQuery from '../../common/useClientQuery';
import { COMMENTS_URL } from '../../consts';

import type { CommentsCountData } from '@haaretz/s-data-structure-types';

const CACHE_TIME = 1000 * 60; // 1 minute

export default function useCommentsCount({ enabled }: { enabled?: boolean }) {
  const articleId = useArticleId();

  const searchParams = new URLSearchParams(
    discardNullableValues({
      action: 'totalCount',
      articleId,
    })
  );

  return useClientQuery<CommentsCountData>({
    cacheKey: 'commentsCount',
    url: COMMENTS_URL,
    searchParams,
    fetchOptions: { method: 'GET' },
    clientOptions: {
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      retry: false,
      enabled,
    },
  });
}
