'use client';

import { appScopeName } from '@haaretz/s-consts';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

/**
 * This hook is used to check if the native application has showing the webview.
 * If used in the web browser, it will return true immediately.
 *
 * @param onLoad - A callback to be called when the native application has showing the webview.
 */
export default function useShowAudioReader(onLoad?: () => void) {
  const toggleAudioReaderCallHistory =
    typeof window === 'undefined'
      ? /* istanbul ignore next */
        undefined
      : (window[appScopeName]?.Iphone.callHistory.toggleAudioReader ?? undefined);

  const [isShowing, setIsShowing] = React.useState(false);

  const shouldLoad = isShowing || toggleAudioReaderCallHistory?.at(-1)?.[0] === 'show';

  const onShowingAudioReader = React.useCallback((event: CustomEvent<(string | undefined)[]>) => {
    const args = event.detail || [];

    const [action] = args;

    setIsShowing(action === 'show');
  }, []);

  useOnce(
    () => {
      onLoad?.();
    },
    shouldLoad && typeof onLoad === 'function'
  );

  useDocumentEventListener('toggleAudioReader', onShowingAudioReader, false);

  return shouldLoad;
}
